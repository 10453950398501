import { defineStore } from "pinia"
import { useLocalStorage, type RemovableRef } from "@vueuse/core"

interface DarkModeState {
  darkModeValue: RemovableRef<boolean>
}

export const useDarkModeStore = defineStore("darkMode", {
  state: (): DarkModeState => ({
    darkModeValue: useLocalStorage(
      "darkMode",
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ),
  }),
  getters: {
    darkMode(state) {
      if (localStorage.getItem("darkMode") === null) {
        localStorage.setItem("darkMode", "false")
      }

      return state.darkModeValue
    },
  },
  actions: {
    toggleDarkMode() {
      this.darkModeValue = !this.darkModeValue //toggle
      localStorage.setItem("darkMode", this.darkModeValue.toString()) // update local storage
      const el = document.querySelectorAll("html")[0]

      if (this.darkModeValue) {
        el.classList.add("dark")
      } else {
        el.classList.remove("dark")
      }
    },
    setDarkModeState() {
      let localState = localStorage.getItem("darkMode") //string in local
      const el = document.querySelectorAll("html")[0]

      if (localState === "true") {
        el.classList.add("dark")
      } else {
        el.classList.remove("dark")
      }
    },
  },
})
