<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xl:col-span-6">
      <div class="-mb-10 pb-10">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 2xl:gap-x-0 2xl:pl-6">
          <div class="col-span-12 mt-8">
            <!-- Left content here -->
            <slot name="left-content"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 xl:col-span-6">
      <div class="-mb-10 pb-10 xl:border-l">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 2xl:gap-x-0 2xl:pl-6">
          <div class="col-span-12 mt-8">
            <!-- Right content here -->
            <slot name="right-content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
