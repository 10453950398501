<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { omit } from "lodash-es";
import { twMerge } from "tailwind-merge";
import { useAttrs, computed } from "vue";

interface FooterProps {
  as?: string | object;
}

const { as } = withDefaults(defineProps<FooterProps>(), {
  as: "div",
});

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge([
    "px-5 py-3 text-right border-t border-slate-200/60 dark:border-darkmode-400",
    typeof attrs.class === "string" && attrs.class,
  ])
);
</script>

<template>
  <component :is="as" :class="computedClass" v-bind="omit(attrs, 'class')">
    <slot></slot>
  </component>
</template>
