import { parseUrlStringForAppId } from "@/router/routerFuncs";
import { genericRouteGuardResult } from "@/router/routerFuncs";

const routes = [
	{
		path: "/private5g",
		name: "private5g",
		component: () => import("@apps/private5g/src/App.vue"),
		children: [
			{
				path: "",
				name: "private5g.home",
				component: () => import("@apps/private5g/src/pages/Home.vue"),
			},
			{
				path: "sectorCarriers",
				name: "private5g.sectorCarriers",
				component: () => import("@apps/private5g/src/pages/SectorCarriers.vue"),
			},
			{
				path: "radios",
				name: "private5g.radios",
				component: () => import("@apps/private5g/src/pages/Radios.vue"),
			},
			{
				path: "alarms",
				name: "private5g.alarms",
				component: () => import("@apps/private5g/src/pages/RanAlarms.vue"),
			},
			{
				path: "networks",
				name: "private5g.networks",
				component: () => import("@apps/private5g/src/pages/Networks.vue"),
			},
		],
	},
];

const menuIndex = 1;

const menuItems = [
	{
		icon: "Activity",
		pageName: "private5g.home",
		title: "Private 5g Dashboard",
		// subMenu: [
		// 	{
		// 		icon: "Activity",
		// 		pageName: "private5g.home",
		// 		title: "Home",
		// 	},
		// ],
	},
];
export const submoduleRouteGuard = (router: any) => {
	return async (to: any, from: any, next: any) => {
		// step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
		let guardRequirements = {
			appId: "private-5g",
			baseRoles: ["5g-admin", "5g-read"],
		};
		let parsedAppID: string | null = parseUrlStringForAppId(to.path);
		if (parsedAppID === guardRequirements.appId) {
			// step 2 - inside of this submodule - check if the role is allowed/valid
			const routeGuardAllowed = await genericRouteGuardResult(
				guardRequirements
			);
			if (routeGuardAllowed) {
				next();
			} else {
				// abort navigation
				next("/"); // Redirect to home page - show no access message
			}
		} else {
			next(); // Allow other submodule routes and routes
		}
	};
};

export default {
	routes,
	menuIndex,
	menuItems,
};
