<template>
  <Notification id="delete-notification-content" class="relative hidden">
    <div class="flex max-w-132">
      <div class="flex items-center">
        <Lucide icon="Trash2" class="text-white" />
        <span
          class="absolute bottom-1.5 left-14 text-xs text-gray-400 dark:text-darkmode-100"
        >
          {{ timeSince(date as Date) }}
        </span>
      </div>
      <div class="w-full pl-4 pr-4">
        <div class="break-words font-medium">{{ message }}</div>
      </div>
    </div>
  </Notification>
</template>

<script setup lang="ts">
import { watch, nextTick, ref, PropType } from "vue"
import Notification from "@/components/Base/Notification"
import Toastify from "toastify-js"
import Lucide from "@/components/Base/Lucide"
import { useModalStore } from "@/stores/modals"
const modalStore = useModalStore()

type OnClick = string | undefined | Function

const props = defineProps({
  message: {
    type: String,
    required: false,
    default: "This is a message!",
  },
  showToast: {
    type: Number,
    required: false,
    default: false,
  },
  duration: {
    type: Number,
    required: false,
    default: 3000,
  },
  onClick: {
    type: Function as PropType<OnClick>,
    required: false,
    default: undefined,
  },
  date: {
    type: Date,
    required: false,
    default: undefined,
  },
})

function timeSince(date: Date): string {
  const now = new Date()
  const secondsPast = (now.getTime() - date?.getTime()) / 1000

  // If the date is within the last 24 hours
  if (secondsPast < 86400) {
    // 24 * 60 * 60
    return date?.toLocaleTimeString("no-nb")
  }

  // If the date was exactly one day ago
  const days = Math.round(secondsPast / 86400)
  if (days === 1) {
    return "Yesterday"
  }

  // If the date was more than one day ago
  return days + " days ago"
}

const emit = defineEmits(["UpdateCloseVal"])

function findObjectIndex(arr: Array<any>, obj: object) {
  const objStr = JSON.stringify(obj)
  return arr.findIndex((item) => JSON.stringify(item) === objStr)
}

function removeObject(arr: Array<any>, obj: object) {
  const index = findObjectIndex(arr, obj)
  if (index !== -1) {
    arr.splice(index, 1)
  }
  return arr
}

const showDeleteToast = () => {
  const successElements = document.querySelectorAll(
    "#delete-notification-content"
  )

  const msg = ref(props.message)
  const duration = ref(props.duration)
  const onClick = ref(props.onClick)
  const date = ref(props.date)

  const failedEl = successElements[successElements.length - 1].cloneNode(
    true
  ) as HTMLElement
  failedEl.classList.remove("hidden")
  Toastify({
    offset: {
      x: 0,
      y: 60,
    },
    node: failedEl,
    duration: props.duration,
    newWindow: true,
    close: true,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
    onClick: onClick.value as () => void,
    callback: () => {
      removeObject(modalStore.activeToasts, {
        msg: msg.value,
        toastType: "delete",
        duration: duration.value,
        onClick: onClick.value?.toString(),
        date: date.value,
      })
    },
  }).showToast()
}

watch(
  () => props.showToast,
  () => {
    nextTick(() => {
      showDeleteToast()
    })
  }
)
</script>
