<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="intro-y mt-5">
        <div
          :class="[
            'intro-y relative mt-5',
            'before:box before:absolute before:inset-x-3 before:mt-3 before:h-full before:bg-slate-50 before:content-[\'\']',
          ]">
          <div class="box grid grid-cols-12">
            <div class="col-span-12 flex flex-col justify-center px-8 py-12">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
