// esim/src/router.ts
import {
  genericRouteGuardResult,
  parseUrlStringForAppId,
} from "@/router/routerFuncs"

const routes = [
  {
    path: "/sim",
    name: "sim",
    meta: { title: "SIM Management" },
    component: () => import("@apps/sim/src/App.vue"),
    children: [
      {
        path: "",
        name: "sim.home",
        component: () => import("@apps/sim/src/pages/Home.vue"),
      },
      {
        path: "create-order",
        name: "sim.create-order",
        meta: { title: "Create Order" },
        component: () => import("@apps/sim/src/pages/CreateOrder.vue"),
      },
      {
        path: "add-product",
        name: "sim.add-product",
        meta: { title: "Add Product" },
        component: () => import("@apps/sim/src/pages/AddProduct.vue"),
      },
      {
        path: "order/:orderId",
        meta: { title: "Order" },
        children: [
          {
            name: "sim.order",
            path: "",
            component: () => import("@apps/sim/src/pages/Order.vue"),
          },
          {
            path: "orderLine/:orderLineId",
            name: "sim.order-line",
            meta: { title: "Order Line" },
            component: () => import("@apps/sim/src/pages/OrderLine.vue"),
          },
        ],
      },
      {
        path: "project/:projectId",
        meta: { title: "Project" },
        name: "sim.project",
        component: () => import("@apps/sim/src/pages/Project.vue"),
      },
      {
        path: "product/:productId",
        meta: { title: "Product" },
        children: [
          {
            name: "sim.product",
            path: "",
            component: () => import("@apps/sim/src/pages/Product.vue"),
          },
          {
            path: "subscription/:subscriptionId",
            meta: { title: "Subscription" },
            children: [
              {
                name: "sim.subscription",
                path: "",
                component: () => import("@apps/sim/src/pages/Subscription.vue"),
              },
              {
                path: "provider/:providerId",
                name: "sim.provider",
                meta: { title: "provider" },
                component: () => import("@apps/sim/src/pages/Provider.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "simCard/:simCardId",
        name: "sim.sim-card",
        meta: { title: "SIM Card" },
        component: () => import("@apps/sim/src/pages/SimCard.vue"),
      },
      {
        path: "simCard/edit",
        name: "sim.sim-card-edit",
        meta: { title: "Edit SIM Card" },
        component: () => import("@apps/sim/src/pages/EditSim.vue"),
      },
    ],
  },
]

const menuIndex = 2

const menuItems = [
  {
    icon: "Signal",
    pageName: "sim.home",
    title: "SIM",
  },
]

export const submoduleRouteGuard = (router: any) => {
  return async (to: any, from: any, next: any) => {
    // step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
    let guardRequirements = {
      appId: "sim",
      baseRoles: ["sim-tm-basic", "sim-basic", "sim-tm-admin", "sim-admin"],
    }
    let parsedAppID: string | null = parseUrlStringForAppId(to.path)
    if (parsedAppID === guardRequirements.appId) {
      // step 2 - inside of this submodule - check if the role is allowed/valid
      const routeGuardAllowed = await genericRouteGuardResult(guardRequirements)
      if (routeGuardAllowed) {
        next()
      } else {
        // abort navigation
        next("/") // Redirect to home page - show no access message
      }
    } else {
      next() // Allow other submodule routes and routes
    }
  }
}

export default {
  routes,
  menuIndex,
  menuItems,
}
