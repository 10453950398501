export const LOCALIZATION = {
  about: 'About',
  add: 'Add',
  addApplication: 'Add application',
  addShips: 'Add ship',
  addTopic: 'Add topic',
  addTopicToAlias: 'Add topic to alias',
  alias: 'Alias',
  all: 'All',
  apiClients: 'API clients',
  apiClientCreated: 'API client created',
  apiClientDeleted: 'API client deleted',
  apiDocumentation: 'API documentation',
  apiToken: 'API token',
  application: 'Application',
  applicationAddedToShip: 'Application added to ship',
  applicationDeleted: 'Application deleted',
  applicationDetails: 'Application details',
  applicationRemovedFromShip: 'Application removed from ship',
  applications: 'Applications',
  applicationSaved: 'Application saved',
  applicationSubscribedToTopic: 'Application subscribed to topic',
  applicationsOnShip: 'Applications on ship',
  applicationTopics: 'Application topics',
  applicationUnsubscribedFromTopic: 'Application unsubscribed from topic',
  appOwner: 'App owner',
  apps: 'Apps',
  areYouSure: 'Are you sure?',
  back: 'Back',
  cancel: 'Cancel',
  clientId: 'Client id',
  clientSecret: 'Client secret',
  close: 'Close',
  company: 'Company',
  companyInformation: 'Company information',
  companyEmail: 'Company email',
  companyName: 'Company name',
  companyUpdatedSuccessfully: 'Company updated successfully',
  clientManagementAppName: 'Client Management',
  createdBy: 'Created by',
  customer: 'Customer',
  dataOwner: 'Data owner',
  delete: 'Delete',
  deleteDialogNotification: 'Do you really want to delete these records? \n This process cannot be undone',
  description: 'Description',
  details: 'Details',
  developer: 'Developer',
  dns: 'DNS',
  dnvVis: 'DNV VIS',
  download: 'Download',
  edit: 'Edit',
  editAccess: 'Edit access',
  editShip: 'Edit ship',
  editTopicAccess: 'Edit topic access',
  editUser: 'Edit user',
  email: 'Email',
  encoded: 'Encoded',
  externalAccess: 'External access',
  fileDeleted: 'File deleted',
  files: 'Files',
  filesOn: 'Files on',
  fileSync: 'File sync',
  fileUploaded: 'File uploaded',
  filters: 'Filters',
  firstName: 'First name',
  forApplication: 'For application',
  giveReadAccess: 'Give read access',
  greenIndicatesPublicTopic: 'Green mark indicates that topic is public',
  id: 'Id',
  imoNumber: 'IMO number',
  ipRange: 'IP Range',
  japaneseStandardJsmea: 'Japanese standard JSMEA',
  jsmea: 'JSMEA',
  lastName: 'Last name',
  loadMore: 'Load more',
  makeCollectionAvailableForOtherCompanies: 'Make this collection available for other companies',
  maritimeContext: 'Maritime context',
  memberOfGroup: 'Member of group',
  name: 'Name',
  namingRule: 'Naming rule',
  onboard: 'Onboard',
  owner: 'Owner',
  pinkIndicatesTopicIsOwnedByOtherCompany: 'Pink mark indicates that topic is owned by another company',
  public: 'Public',
  publicPrivateOwner: 'Public private owner',
  readMore: 'Read more',
  registerAlias: 'Register alias',
  registerTopic: 'Register topic',
  save: 'Save',
  search: 'Search',
  selectFile: 'Select file',
  serviceName: 'Service name',
  settings: 'Settings',
  shared: 'Shared',
  ship: 'Ship',
  shipDns: 'Ship DNS',
  shipInformation: 'Ship information',
  ships: 'Ships',
  shipSaved: 'Ship saved',
  shipsUpdatesSuccessfully: 'Ships updated successfully',
  shipUuid: 'Ship uuid',
  shortName: 'Short name',
  size: 'Size',
  sort: 'Sort',
  state: 'State',
  subscribe: 'Subscribe',
  subscribeToTopic: 'Subscribe to topic',
  superUser: 'Super user',
  telenorMaritime: 'Telenor Maritime',
  telenorMaritimeSysAdmin: 'Telenor Maritime sys admin',
  thereAreNoItems: 'There are no items',
  thisFieldIsRequired: 'This field is required',
  topic: 'Topic',
  topicAccessForEntireFleet: 'Topic access for entire fleet',
  topicAccessProvidedToCompany: 'Topic access provided to company',
  topicAccessRemovedFromShip: 'Topic access removed from ship',
  topicAddedToTopicsAlias: 'Topic added to topics alias',
  topicCreated: 'Topic created',
  topicDeleted: 'Topic deleted',
  topicIsPublicOtherAppsCanSubscribeAsReadonly: 'This topic is public, so other apps can subscribe as readonly',
  topicPath: 'Topic path',
  topicPathIsNotValid: 'Topic path is not valid',
  topicRemovedFromTopicAlias: 'Topic removed from topic alias',
  topics: 'Topics',
  topicsAccess: 'Topics access',
  topicsAliasDeleted: 'Topics alias deleted',
  topicsAliasSaved: 'Topics alias saved',
  topicsAliases: 'Topics aliases',
  topicsCatalog: 'Topics catalog',
  topicsInApplication: 'Topics in application',
  topicsInAlias: 'Topics in alias',
  topicsMapping: 'Topics mapping',
  topicsMappingDeleted: 'Topics mapping deleted',
  topicsMappingSaved: 'Topics mapping saved',
  topicsReport: 'Topics report',
  topicSubscribersIsOnlyControllableByApplicationOwner: 'Topics subscribers is only controllable with Application Owner',
  topicSubscription: 'Topic subscription',
  unifiedHostingService: 'Unified Hosting Service™',
  updateFromSites: 'Update from sites',
  upload: 'Upload',
  uploadFile: 'Upload file',
  user: 'User',
  username: 'Username',
  userUpdated: 'User updated',
  users: 'Users',
  view: 'View',
  viewApplications: 'View applications',
  vis: 'VIS',
  youDontHavePermissionsToViewThisPage: 'You don\'t have permissions to view this page',
  yourProfile: 'Your profile',
  showEncoded: 'Show encoded',
};
