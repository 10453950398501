<template>
  <Layout>
    <RouterView />
  </Layout>
  <WarningModal
    :open-me="modalStore.modal.warning.show"
    :header="modalStore.modal.warning.header"
    :content="modalStore.modal.warning.msg"
    @update:open-me="modalStore.deactivateModal('warning')"
  />
  <NotAllowedModal
    :open-me="modalStore.modal.notAllowed.show"
    :header="modalStore.modal.notAllowed.header"
    :content="modalStore.modal.notAllowed.msg"
    @update:open-me="modalStore.deactivateModal('notAllowed')"
  />
  <ErrorModal
    :open-me="modalStore.modal.error.show"
    :header="modalStore.modal.error.header"
    :content="modalStore.modal.error.msg"
    @update:open-me="modalStore.deactivateModal('error')"
  />
  <SuccessModal
    :open-me="modalStore.modal.success.show"
    :header="modalStore.modal.success.header"
    :content="modalStore.modal.success.msg"
    @update:open-me="modalStore.deactivateModal('success')"
  />

  <FailedToast
    :showToast="modalStore.toast.failed.show"
    :message="modalStore.toast.failed.msg"
    :duration="modalStore.toast.failed.duration"
    :on-click="modalStore.toast.failed.onClick"
    :date="modalStore.toast.failed.date"
  />
  <SuccessToast
    :showToast="modalStore.toast.success.show"
    :message="modalStore.toast.success.msg"
    :duration="modalStore.toast.success.duration"
    :on-click="modalStore.toast.success.onClick"
    :date="modalStore.toast.success.date"
  />
  <MessageToast
    :showToast="modalStore.toast.message.show"
    :message="modalStore.toast.message.msg"
    :duration="modalStore.toast.message.duration"
    :on-click="modalStore.toast.message.onClick"
    :date="modalStore.toast.message.date"
  />
  <DeleteToast
    :showToast="modalStore.toast.delete.show"
    :message="modalStore.toast.delete.msg"
    :duration="modalStore.toast.delete.duration"
    :on-click="modalStore.toast.delete.onClick"
    :date="modalStore.toast.delete.date"
  />
  <WarningToast
    :showToast="modalStore.toast.warning.show"
    :message="modalStore.toast.warning.msg"
    :duration="modalStore.toast.warning.duration"
    :on-click="modalStore.toast.warning.onClick"
    :date="modalStore.toast.warning.date"
  />
</template>
<script setup lang="ts">
import Layout from "@/themes"
import { useModalStore } from "./stores/modals"
import WarningModal from "./components/Custom/Modals/WarningModal.vue"
import NotAllowedModal from "./components/Custom/Modals/NotAllowedModal.vue"
import ErrorModal from "./components/Custom/Modals/ErrorModal.vue"
import SuccessModal from "./components/Custom/Modals/SuccessModal.vue"
import FailedToast from "./components/Custom/Modals/Notifications/FailedToast.vue"
import SuccessToast from "./components/Custom/Modals/Notifications/SuccessToast.vue"
import MessageToast from "./components/Custom/Modals/Notifications/MessageToast.vue"
import DeleteToast from "./components/Custom/Modals/Notifications/DeleteToast.vue"
import WarningToast from "./components/Custom/Modals/Notifications/WarningToast.vue"
import { onMounted } from "vue"

const modalStore = useModalStore()

onMounted(async () => {
  await modalStore.activateBacklogToasts()
})
</script>
