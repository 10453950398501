import { useSecurityStore } from "@/stores/security"
import { useMenuStore } from "@/stores/menu"
import { type Menu } from "@/stores/menu"
import { submoduleMenuInfo } from "./submoduleRoutes"
import { filterUserGroups } from "@/auth/authFunctions"

// export function createMenu(menuDetails, enabledApps) {
// 	const menuStore = useMenuStore();

// 	// Initialize a map to keep track of parent menus and their sub-menus
// 	const parentMenus = {};

// 	enabledApps.forEach(async (app) => {
// 		if (menuDetails[app] !== undefined) {
// 			menuDetails[app].menuItems.forEach((subMenuItem) => {
// 				const parent = subMenuItem?.parent ?? "";
// 				if (parent) {
// 					if (!parentMenus[parent]) {
// 						// Initialize parent menu item if not already done
// 						parentMenus[parent] = {
// 							icon: getParentIcon(parent),
// 							pageName: parent,
// 							title: getParentTitle(parent),
// 							subMenu: [],
// 						};
// 					}
// 					// Add sub-menu item to the parent menu
// 					parentMenus[parent].subMenu.push(subMenuItem);
// 				} else {
// 					// Add standalone menu item
// 					addMenuItem(menuDetails[app].menuItems, menuDetails[app].menuIndex);
// 				}
// 			});
// 		}
// 	});

// 	// Add all parent menus with their sub-menus to the menu store
// 	Object.keys(parentMenus).forEach((parent) => {
// 		if (!menuStore.menuValue.some((item) => item.pageName === parent)) {
// 			menuStore.menuValue.push(parentMenus[parent]);
// 		}
// 	});
// }

export function createMenu(menuDetails, enabledApps) {
  // cycle through all enabled submodule apps and add menu items
  enabledApps.forEach(async (app) => {
    if (menuDetails[app] !== undefined) {
      addMenuItem(menuDetails[app].menuItems, menuDetails[app].menuIndex)
    }
  })
}


export async function addMenuItem(routeMenuItems: any, routeMenuIndex: any) {
  // init store context in function scope
  const menuStore = useMenuStore()
  routeMenuItems.forEach(async (item: any) => {
    let menuExists = await checkMenuItemsExists(item.pageName)
    if (!menuExists) {
      if (routeMenuIndex !== null) {
        // Insert the item at the specified index
        menuStore.menuValue.splice(routeMenuIndex, 0, item)
      } else {
        // If no index is specified or the index is larger than the length of the menu array,
        // add the item at the end
        menuStore.menuValue.push(item)
      }
    }
  })
}

export async function checkMenuItemsExists(appMenuItem: any) {
  const menuStore = useMenuStore()
  return menuStore.menuValue.some((item: Menu) => {
    return item.pageName === appMenuItem
  })
}

export async function getArrayOfValidatedApps() {
  //used to big routes so must inlcude only submodule apps
  const securityStore = useSecurityStore()
  const deployedApps = Object.keys(submoduleMenuInfo)
  console.log("validdddd", deployedApps, securityStore.apps)
  let validatedAppList = deployedApps.filter((element) =>
    securityStore.apps.includes(element)
  )
  return validatedAppList
}

type genericGuardT = {
  appId: string
  baseRoles: string[]
}

export async function genericRouteGuardResult(
  guardReqs: genericGuardT
): Promise<boolean> {
  //  handle app roles
  const securityStore = useSecurityStore()
  let userRoles = securityStore.groups.app_roles[guardReqs.appId]
  let validGroup: boolean = await filterUserGroups(
    guardReqs.baseRoles,
    userRoles
  )
  if (validGroup) {
    return true // Allow navigation to the route
  } else {
    // Disallow navigation to the route
    return false
  }
}

export function parseUrlStringForAppId(originalUrlString: string) {
  //split string by / and get the first element which should be submodule
  if (originalUrlString.includes("/")) {
    let splitString = originalUrlString.split("/")

    if (splitString.length >= 1) {
      return splitString[1]
    }
  }
  return null // catch all
}

// function getParentIcon(parent) {
// 	const icons = {
// 		uhs: "RadioTower",
// 		// Add more parent icons as needed
// 	};
// 	return icons[parent] || "DefaultIcon";
// }

// function getParentTitle(parent) {
// 	const titles = {
// 		uhs: "UHS",
// 		// Add more parent titles as needed
// 	};
// 	return titles[parent] || parent.charAt(0).toUpperCase() + parent.slice(1);
// }
