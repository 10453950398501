import {LOCALIZATION} from "@apps/client-management/src/global/localization";
import {PAGE} from "@apps/client-management/src/global/page";
import {genericRouteGuardResult, parseUrlStringForAppId} from "@/router/routerFuncs";

const routes = [
  {
    path: '/client-management',
    name: LOCALIZATION.clientManagementAppName,
    component: () => import('@apps/client-management/src/App.vue'),
    children: [
      {
        path: '',
        name: PAGE.home.root,
        component: () => import('@apps/client-management/src/pages/HomePage.vue')
      },
      {
        path: 'user',
        name: PAGE.user.root,
        children: [
          {
            path: '',
            name: PAGE.user.list,
            component: () => import('@apps/client-management/src/pages/UsersPage.vue')
          },
          {
            path: ':userId',
            name: PAGE.user.edit,
            component: () => import('@apps/client-management/src/pages/UserEditPage.vue')
          }
        ]
      },
      {
        path: 'company',
        name: PAGE.company.root,
        component: () => import('@apps/client-management/src/pages/CompanyPage.vue')
      },
      {
        path: 'app',
        name: PAGE.application.root,
        children: [
          {
            path: '',
            name: PAGE.application.list,
            component: () => import('@apps/client-management/src/pages/ApplicationsPage.vue')
          },
          {
            path: ':appId',
            name: PAGE.application.topics,
            component: () => import('@apps/client-management/src/pages/ApplicationTopicsPage.vue')
          }
        ]
      },
      {
        path: 'topic-alias',
        name: PAGE.topicAlias.root,
        children: [
          {
            path: '',
            name: PAGE.topicAlias.list,
            component: () => import('@apps/client-management/src/pages/TopicsAliasesPage.vue'),
          },
          {
            path: ':aliasId',
            name: PAGE.topicAlias.topics,
            component: () => import('@apps/client-management/src/pages/TopicsAliasDetailsPage.vue')
          }
        ]
      },
      {
        path: 'topic-external-access',
        children: [
          {
            path: 'ship/:shipId',
            name: PAGE.topicExternalAccess.ship,
            component: () => import('@apps/client-management/src/pages/TopicsExternalAccessPage.vue')
          },
          {
            path: 'ship/:shipId/app/:appId',
            name: PAGE.topicExternalAccess.shipApp,
            component: () => import('@apps/client-management/src/pages/TopicsExternalAccessPage.vue')
          },
          {
            path: 'edit',
            children: [
              {
                path: ':topicId',
                name: PAGE.topicExternalAccess.edit.topic,
                component: () => import('@apps/client-management/src/pages/TopicExternalAccessEditPage.vue')
              },
              {
                path: ':topicId/ship/:shipId/app/:appId',
                name: PAGE.topicExternalAccess.edit.shipApp,
                component: () => import('@apps/client-management/src/pages/TopicExternalAccessEditPage.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'topics-external-access-multiple',
        name: PAGE.topicExternalAccessMultiple.root,
        children: [
          {
            path: '',
            name: PAGE.topicExternalAccessMultiple.list,
            component: () => import('@apps/client-management/src/pages/TopicsExternalAccessMultiple.vue')
          },
          {
            path: 'topic/:topicId',
            name: PAGE.topicExternalAccessMultiple.edit,
            component: () => import('@apps/client-management/src/pages/TopicsExternalAccessMultipleEditPage.vue')
          },
        ]
      },
      {
        path: 'ships',
        name: PAGE.ship.root,
        children: [
          {
            path: '',
            name: PAGE.ship.list,
            component: () => import('@apps/client-management/src/pages/ShipsPage.vue')
          },
          {
            path: ':shipId',
            name: PAGE.ship.edit,
            component: () => import('@apps/client-management/src/pages/ShipDetailsPage.vue')
          }
        ]
      },
      {
        path: 'file-sync',
        name: PAGE.fileSync.root,
        children: [
          {
            path: '',
            name: PAGE.fileSync.ships,
            component: () => import('@apps/client-management/src/pages/FileSyncShipsPage.vue')
          },
          {
            path: ':shipId',
            name: PAGE.fileSync.folders,
            component: () => import('@apps/client-management/src/pages/FileSyncShipFoldersPage.vue')
          },
          {
            path: ':shipId/app/:appId',
            name: PAGE.fileSync.files,
            component: () => import('@apps/client-management/src/pages/FileSyncFolderFilesPage.vue')
          }
        ]
      },
      {
        path: 'topic-mapping',
        name: PAGE.topicMapping.list,
        component: () => import('@apps/client-management/src/pages/TopicsMappingsPage.vue')
      },
      {
        path: 'topics-report',
        name: PAGE.topicReport.list,
        component: () => import('@apps/client-management/src/pages/TopicsReportOverviewPage.vue')
      },
      {
        path: 'profile',
        name: PAGE.profile.root,
        component: () => import('@apps/client-management/src/pages/YourProfilePage.vue')
      },
      {
        path: 'about',
        name: PAGE.about.root,
        component: () => import('@apps/client-management/src/pages/AboutPage.vue')
      }
    ],
  },
];

const menuIndex = 1

const menuItems = [
  {
    icon: 'Users',
    pageName: 'client-management',
    title: LOCALIZATION.clientManagementAppName,
    subMenu: [
      {
        icon: 'Users',
        pageName: PAGE.user.list,
        title: LOCALIZATION.users,
      },
      {
        icon: 'Building2',
        pageName: PAGE.company.root,
        title: LOCALIZATION.company
      },
      {
        icon: 'LayoutGrid',
        pageName: PAGE.application.list,
        title: LOCALIZATION.applications
      },
      {
        icon: 'FolderSync',
        pageName: PAGE.fileSync.ships,
        title: LOCALIZATION.fileSync
      },
      {
        icon: 'ClipboardList',
        pageName: PAGE.topicReport.list,
        title: LOCALIZATION.topicsReport
      },
      {
        icon: 'Copy',
        pageName: PAGE.topicAlias.list,
        title: LOCALIZATION.topicsAliases
      },
      {
        icon: 'Ship',
        pageName: PAGE.ship.list,
        title: LOCALIZATION.ships
      },
      {
        icon: 'User',
        pageName: PAGE.profile.root,
        title: LOCALIZATION.yourProfile
      },
      {
        icon: 'AreaChart',
        pageName: PAGE.topicMapping.list,
        title: LOCALIZATION.topicsMapping
      },
      {
        icon: 'Info',
        pageName: PAGE.about.root,
        title: LOCALIZATION.about
      }
    ],
  }
];

export const submoduleRouteGuard = (router: any) => {
  return async (to: any, from: any, next: any) => {
    // step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
    let guardRequirements = {
      appId: "client-management",
      baseRoles: ["cm-user"],
    }
    let parsedAppID: string | null = parseUrlStringForAppId(to.path)
    
    if (parsedAppID === guardRequirements.appId) {
      // step 2 - inside of this submodule - check if the role is allowed/valid
      const routeGuardAllowed = await genericRouteGuardResult(guardRequirements)
      if (routeGuardAllowed) {
        next()
      } else {
        // abort navigation
        next("/") // Redirect to home page - show no access message
      }
    } else {
      next() // Allow other submodule routes and routes
    }
  }
}

export default {
  routes,
  menuIndex,
  menuItems
};
