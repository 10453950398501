<template>
  <div>
    <Dialog staticBackdrop :open="showModal" @close="setShowModal(false)">
      <Dialog.Panel>
        <div class="p-5 text-center">
          <Lucide
            icon="AlertTriangle"
            class="mx-auto mt-3 h-16 w-16 text-danger"
          />
          <div class="mt-5 text-3xl">
            <slot :name="'header'">{{ header }}</slot>
          </div>
          <div class="mt-2 text-slate-400">
            <slot>{{ content }}</slot>
          </div>
        </div>
        <div class="px-5 pb-8 text-center">
          <Button
            type="button"
            variant="primary"
            @click="setShowModal(false)"
            class="w-24"
          >
            {{ button }}
          </Button>
        </div>
      </Dialog.Panel>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import Lucide from "@/components/Base/Lucide"
import Button from "@/components/Base/Button"
import { Dialog } from "@/components/Base/Headless"

const emit = defineEmits(["update:openMe"])

const setShowModal = (value: boolean) => {
  showModal.value = value
}

const props = defineProps({
  openMe: {
    type: Boolean,
    required: true,
    default: false,
  },
  header: {
    type: String,
    required: false,
    default: "An error occured",
  },
  content: {
    type: String,
    required: false,
    default: "Womp womp",
  },
  button: {
    type: String,
    required: false,
    default: "Ok",
  },
})

const showModal = computed({
  get: () => props.openMe,
  set: (value) => {
    emit("update:openMe", value)
  },
})
</script>
