import wifipkgmgmntRouter from '../../apps/wifipkgmgmnt/src/router.ts';
import uhsmanRouter from '../../apps/uhsman/src/router.ts';
import simRouter from '../../apps/sim/src/router.ts';
import dataguiRouter from '../../apps/datagui/src/router.ts';
import clientManagementRouter from '../../apps/client-management/src/router.ts';
import componentsRouter from '../../apps/components/src/router.ts';
import private5gRouter from '../../apps/private5g/src/router.ts';

export const submoduleRoutes = [wifipkgmgmntRouter.routes, uhsmanRouter.routes, simRouter.routes, dataguiRouter.routes, clientManagementRouter.routes, componentsRouter.routes, private5gRouter.routes].flat();

import { submoduleRouteGuard as wifipkgmgmntGuard } from '../../apps/wifipkgmgmnt/src/router.ts';
import { submoduleRouteGuard as uhsmanGuard } from '../../apps/uhsman/src/router.ts';
import { submoduleRouteGuard as simGuard } from '../../apps/sim/src/router.ts';
import { submoduleRouteGuard as dataguiGuard } from '../../apps/datagui/src/router.ts';
import { submoduleRouteGuard as clientManagementGuard } from '../../apps/client-management/src/router.ts';
import { submoduleRouteGuard as componentsGuard } from '../../apps/components/src/router.ts';
import { submoduleRouteGuard as private5gGuard } from '../../apps/private5g/src/router.ts';

export const submoduleGuards = {wifipkgmgmntGuard, uhsmanGuard, simGuard, dataguiGuard, clientManagementGuard, componentsGuard, private5gGuard};

export const submoduleMenuInfo = {"wifipkgmgmnt": {
      menuItems: wifipkgmgmntRouter.menuItems,
      menuIndex: wifipkgmgmntRouter.menuIndex,
    },
  "uhsman": {
      menuItems: uhsmanRouter.menuItems,
      menuIndex: uhsmanRouter.menuIndex,
    },
  "sim": {
      menuItems: simRouter.menuItems,
      menuIndex: simRouter.menuIndex,
    },
  "datagui": {
      menuItems: dataguiRouter.menuItems,
      menuIndex: dataguiRouter.menuIndex,
    },
  "client-management": {
      menuItems: clientManagementRouter.menuItems,
      menuIndex: clientManagementRouter.menuIndex,
    },
  "components": {
      menuItems: componentsRouter.menuItems,
      menuIndex: componentsRouter.menuIndex,
    },
  "private5g": {
      menuItems: private5gRouter.menuItems,
      menuIndex: private5gRouter.menuIndex,
    }};