import { defineStore } from "pinia"
// import RubickSideMenu from "@/themes/Rubick/SideMenu";
// import RubickSimpleMenu from "@/themes/Rubick/SimpleMenu";
// import RubickTopMenu from "@/themes/Rubick/TopMenu";
// import IcewallSideMenu from "@/themes/Icewall/SideMenu";
// import IcewallSimpleMenu from "@/themes/Icewall/SimpleMenu";
// import IcewallTopMenu from "@/themes/Icewall/TopMenu";
// import TinkerSideMenu from "@/themes/Tinker/SideMenu";
// import TinkerSimpleMenu from "@/themes/Tinker/SimpleMenu";
// import TinkerTopMenu from "@/themes/Tinker/TopMenu";
// import EnigmaSideMenu from "@/themes/Enigma/SideMenu";
// import EnigmaSimpleMenu from "@/themes/Enigma/SimpleMenu";
// import EnigmaTopMenu from "@/themes/Enigma/TopMenu";
import CustomSideMenu from "@/components/Custom/Theme/SideMenu.vue"
// import CustomTopMenu from "@/components/Custom/Theme/TopMenu.vue"

export const themes = [
  // {
  // 	name: "rubick",
  // 	layout: "side-menu",
  // 	component: CustomSideMenu,
  // },
  // {
  // 	name: "rubick",
  // 	layout: "simple-menu",
  // 	component: RubickSimpleMenu,
  // },
  // {
  // 	name: "rubick",
  // 	layout: "top-menu",
  // 	component: RubickTopMenu,
  // },
  // {
  // 	name: "icewall",
  // 	layout: "side-menu",
  // 	component: IcewallSideMenu,
  // },
  // {
  // 	name: "icewall",
  // 	layout: "simple-menu",
  // 	component: IcewallSimpleMenu,
  // },
  // {
  // 	name: "icewall",
  // 	layout: "top-menu",
  // 	component: IcewallTopMenu,
  // },
  {
    name: "tinker",
    layout: "side-menu",
    component: CustomSideMenu,
  },
  // {
  // 	name: "tinker",
  // 	layout: "simple-menu",
  // 	component: TinkerSimpleMenu,
  // },
  // {
  //   name: "tinker",
  //   layout: "top-menu",
  //   component: CustomTopMenu,
  // },
  // {
  //   name: "enigma",
  //   layout: "side-menu",
  //   component: CustomSideMenu,
  // },
  // {
  // 	name: "enigma",
  // 	layout: "simple-menu",
  // 	component: EnigmaSimpleMenu,
  // },
  // {
  //   name: "enigma",
  //   layout: "top-menu",
  //   component: CustomTopMenu,
  // },
] as const

export type Themes = (typeof themes)[number]

interface ThemeState {
  themeValue: {
    name: Themes["name"]
    layout: Themes["layout"]
  }
}

//only used in layout component - now set hardcoded so not required
// export const getTheme = (search?: {
//   name: Themes["name"]
//   layout: Themes["layout"]
// }) => {
//   const searchValues =
//     search === undefined
//       ? {
//           name: localStorage.getItem("theme"),
//           layout: localStorage.getItem("layout"),
//         }
//       : search
//   return themes.filter((item, key) => {
//     console.log(item, key)
//     return (
//       item.name === searchValues.name && item.layout === searchValues.layout
//     )
//   })[0]
// }

export const useThemeStore = defineStore("theme", {
  state: (): ThemeState => ({
    themeValue: {
      name: "tinker",
      layout: "side-menu",
    },
  }),
  getters: {
    theme(state) {
      if (localStorage.getItem("theme") === null) {
        localStorage.setItem("theme", "tinker")
      }

      if (localStorage.getItem("layout") === null) {
        localStorage.setItem("layout", "side-menu")
      }

      return state.themeValue
    },
  },
  actions: {
    setTheme(theme: Themes["name"]) {
      this.themeValue = {
        name: "tinker",
        layout: "side-menu",
      }

      localStorage.setItem("theme", theme)
    },
    setLayout(layout: Themes["layout"]) {
      this.themeValue = {
        name: "tinker",
        layout: "side-menu",
      }

      localStorage.setItem("layout", layout)
    },
  },
})
