import { useSecurityStore } from "@/stores/security";
import { filterUserGroups } from "@/auth/authFunctions";
import { parseUrlStringForAppId } from "@/router/routerFuncs";
import { genericRouteGuardResult } from "@/router/routerFuncs";

const routes = [
	{
		path: "/uhs",
		name: "uhs",
		component: () => import("@apps/datagui/src/App.vue"),
		children: [
			{
				path: "datagui",
				name: "uhs.datagui",
				component: () => import("@apps/datagui/src/pages/DataGui.vue"),
			},
		],
	},
];

const menuIndex = 3;

const menuItems = [
	{
		icon: "RadioTower",
		pageName: "uhs",
		title: "UHS",
		subMenu: [
			{
				icon: "Activity",
				pageName: "uhs.datagui",
				title: "Data GUI",
			},
		],
	},
];

export const submoduleRouteGuard = (router: any) => {
	return async (to: any, from: any, next: any) => {
		// step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
		let guardRequirements = {
			appId: "datagui",
			baseRoles: ["datagui-admin"],
		};
		let parsedAppID: string | null = parseUrlStringForAppId(to.path);
		if (parsedAppID === guardRequirements.appId) {
			// step 2 - inside of this submodule - check if the role is allowed/valid
			const routeGuardAllowed = await genericRouteGuardResult(
				guardRequirements
			);
			if (routeGuardAllowed) {
				next();
			} else {
				// abort navigation
				next("/"); // Redirect to home page - show no access message
			}
		} else {
			next(); // Allow other submodule routes and routes
		}
	};
};

export default {
	routes,
	menuIndex,
	menuItems,
};
