const pagePrefix = 'client-management'

const pageRoot = {
  home: `${pagePrefix}.home`,
  about: `${pagePrefix}.about`,
  application: `${pagePrefix}.application`,
  company: `${pagePrefix}.company`,
  fileSync: `${pagePrefix}.file-sync`,
  profile: `${pagePrefix}.profile`,
  ship: `${pagePrefix}.ship`,
  topicAlias: `${pagePrefix}.topic-alias`,
  topicCatalog: `${pagePrefix}.topic-catalog`,
  topicExternalAccess: `${pagePrefix}.topic-external-access`,
  topicExternalAccessMultiple: `${pagePrefix}.topic-external-access-multiple`,
  topicMapping: `${pagePrefix}.topic-mapping`,
  topicReport: `${pagePrefix}.topic-report`,
  user: `${pagePrefix}.user`,
};

export const PAGE = {
  home: {
    root: pageRoot.home
  },
  error: 'error-page',
  about: {
    root: pageRoot.about
  },
  application: {
    root: pageRoot.application,
    list: `${pageRoot.application}.list`,
    topics: `${pageRoot}.topics`
  },
  company: {
    root: pageRoot.company
  },
  fileSync: {
    root: pageRoot.fileSync,
    files: `${pageRoot.fileSync}.files`,
    folders: `${pageRoot.fileSync}.folders`,
    ships: `${pageRoot.fileSync}.ships`
  },
  profile: {
    root: pageRoot.profile
  },
  ship: {
    root: pageRoot.ship,
    list: `${pageRoot.ship}.list`,
    edit: `${pageRoot.ship}.edit`
  },
  topicAlias: {
    root: pageRoot.topicAlias,
    list: `${pageRoot.topicAlias}.list`,
    topics: `${pageRoot.topicAlias}.edit`,
  },
  topicCatalog: {
    list: `${pageRoot.topicCatalog}.list`
  },
  topicExternalAccess: {
    root: `${pageRoot.topicExternalAccess}`,
    ship: `${pageRoot.topicExternalAccess}.ship`,
    shipApp: `${pageRoot.topicExternalAccess}.ship-app`,
    edit: {
      topic: `${pageRoot.topicExternalAccess}.edit.topic`,
      shipApp: `${pageRoot.topicExternalAccess}.edit.ship-app`,
    }
  },
  topicExternalAccessMultiple: {
    root: pageRoot.topicExternalAccessMultiple,
    list: `${pageRoot.topicExternalAccessMultiple}.list`,
    edit: `${pageRoot.topicExternalAccessMultiple}.edit`,
  },
  topicMapping: {
    list: `${pageRoot.topicMapping}.list`
  },
  topicReport: {
    list: `${pageRoot.topicReport}.list`
  },
  user: {
    root: pageRoot.user,
    list: `${pageRoot.user}.list`,
    edit: `${pageRoot.user}.edit`
  }
};
