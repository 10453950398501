<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 md:col-span-4 xl:col-span-12">
      <div class="-mb-10 pb-10">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 xl:gap-x-0">
          <div class="col-span-12">
            <!-- Left content here -->
            <slot name="left-content"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-4 xl:col-span-12">
      <div class="-mb-10 pb-10">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 xl:gap-x-0">
          <div class="col-span-12">
            <!-- Center content here -->
            <slot name="center-content"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-4 xl:col-span-12">
      <div class="-mb-10 pb-10">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 xl:gap-x-0">
          <div class="col-span-12">
            <!-- Right content here -->
            <slot name="right-content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
