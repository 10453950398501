<template>
  <footer
    class="relative w-full border-t border-t-telenor-neutral-tint-200 bg-telenor-neutral-tint-50 px-6 py-10 dark:border-t-0 dark:border-darkmode-600 dark:bg-darkmode-800 sm:px-10 sm:dark:border-l"
  >
    <img
      alt="Telenor Maritime Dashboard"
      class="mb-10 h-10"
      :src="smallLogoUrl"
    />
    <div class="mb-6 grid grid-cols-1 gap-8 px-16 sm:grid-cols-2 sm:gap-4">
      <div class="flex flex-col text-black dark:text-white">
        <a
          class="w-fit text-sm"
          href="https://telenormaritime.com/about-us/"
          target="_blank"
        >
          <p
            class="w-fit cursor-pointer font-bold text-lg decoration-2 hover:underline"
          >
            About Telenor Maritime
          </p>
        </a>
        <div class="flex flex-col font-light">
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/about-us/sustainability/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Sustainability
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/about-us/transparency-act/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Transparency Act
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/certifications/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Certifications
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/about-us/management/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Management
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/about-us/history/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              History
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/privacy-statement/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Privacy statement
            </p>
          </a>
        </div>
      </div>
      <div class="flex flex-col text-black dark:text-white">
        <a
          class="w-fit text-sm"
          href="https://telenormaritime.com/contact/"
          target="_blank"
        >
          <p
            class="w-fit cursor-pointer font-bold text-lg decoration-2 hover:underline"
          >
            Business Contact
          </p>
        </a>
        <div class="flex flex-col font-light">
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/noc/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Network Operations Centre
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/contact/product-inquiry/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Solution inquiry
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/contact/logistics-and-customs/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Logistics and customs
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/contact/innovation-proposal/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Innovation proposal
            </p>
          </a>
          <a
            class="w-fit text-sm"
            href="https://telenormaritime.com/contact/marketing-contact/"
            target="_blank"
          >
            <p class="w-fit cursor-pointer decoration-2 hover:underline">
              Contact marketing
            </p>
          </a>
        </div>
      </div>
    </div>
    <RouterLink :to="{ name: 'homepage' }" class="mt-3 flex items-center pt-4">
      <img
        alt="Telenor Maritime Dashboard"
        class="w-48"
        :src="darkModeStore.darkModeValue ? logoUrl : logoUrlBlack"
      />
    </RouterLink>
    <hr class="mb-2 mt-3 border-b dark:border-darkmode-100" />
    <p class="mb-6 text-xs sm:mb-0">
      ©{{ new Date().getFullYear() }}
      <a class="text-xs" href="https://telenormaritime.com/" target="_blank">
        Telenor Maritime
      </a>
    </p>
    <div
      class="absolute bottom-4 left-1/2 -translate-x-1/2 text-center text-telenor-neutral-tint-200 dark:text-darkmode-300"
    >
      <p>Console v{{ version }}</p>
      <p>{{ submoduleName }} {{ submodule?.tag }}</p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import logoUrlBlack from "@/assets/images/tm_logo_black.svg"
import { useDarkModeStore } from "@/stores/dark-mode"
import logoUrl from "@/assets/images/tm_logo_white.svg"
import smallLogoUrl from "@/assets/images/tm_logo_small.svg"
import { useRoute } from "vue-router"
import { version } from "../../../../package.json"
import submodules from "../../../../submodules.conf"
import { computed } from "vue"

const route = useRoute()
const darkModeStore = useDarkModeStore()

const submoduleName = computed(() => {
  if (submodule.value?.module === "wifipkgmgmnt") {
    return "Wi-Fi Package Management"
  } else if (submodule.value?.module === "sim") {
    return "SIM"
  } else {
    return submodule.value?.module ?? ""
  }
})

const submodule = computed(() => {
  return submodules.find((submodule) => {
    return (
      submodule.module ===
      route.fullPath.split("/").find((path) => {
        return path === submodule.module
      })
    )
  })
})
</script>
