import { useSecurityStore } from "@/stores/security"
import router from "../router/index" // .. router/.. required for ts file here
import type { LocationQueryValue } from "vue-router"

//define backend url
export const BFFurl =
  import.meta.env.APP_AUTH_SERVER || "http://localhost:3000/bff"

const BFFAuthURL = BFFurl + "/auth"

// api wrapper with try catch
export async function getApiWrapper(url: string, method: string) {
  let res
  try {
    res = await fetch(url, {
      method: method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
  } catch (error) {
    console.error("Fetch error:", error)
    // todo show error popup for networke error
    return { error: "Network error" }
  }
  let data = await res.json()
  if (data.error) {
    return { error: data.error }
  } else {
    return data
  }
}

// logout function - called from anywhere (not called in main router beforeEach)
export async function logout() {
  let result = await getApiWrapper(BFFAuthURL + "/logout", "GET")
  if (result.error) {
    // to do - logout fail popup
    router.push({ name: "error-page" })
  }
  console.log(result, "logout result")
  // if 200 then call logout func to clear store and then redirect to main page
  emptySecurityStore()
  removeSubRoutes()
  // use this isntead of vue router context
  // on nav, will cause a full page reload, which will reset the JavaScript context and stop all JavaScript execution, including any ongoing API polling or other asynchronous operations
  window.location.href = "/"
}

export async function handleCallbackAuthEvent(
  code: string | LocationQueryValue[],
  state: string | LocationQueryValue[]
) {
  // handle auth callback - get status from bff
  let cbUrl = `${BFFAuthURL}/callback?code=${code}&state=${state}`
  const response = await getApiWrapper(cbUrl, "GET")
  if (response.error) {
    console.log("Error exchanging token on check:", response.error)
    emptySecurityStore()
    // call login func to redrect back to kc
    login() // return not needed as keycloak redirects
    return { error: response.error }
  } else {
    console.log("Successful security callback event")

    // if no error, then user is verified
    // they are pushed back to home where status from server is picked up etc
    //push to  route without query params
    // state and code params removed here
    //additional - maintain previous window context - push to previous route state before login event
    const postLoginRedirectFull = sessionStorage.getItem(
      "postLoginRedirectFull"
    )
    if (postLoginRedirectFull) {
      let parsed = parseUrl(postLoginRedirectFull) // handle params
      sessionStorage.removeItem("postLoginRedirectFull") // Clear the stored path
      return { path: parsed.path, query: parsed.query }
    } else {
      // Navigate to a default route
      return { path: "/" }
    }
  }
}

function parseUrl(url: string) {
  const urlObject = new URL(url, "http://dummy.com") // dummy base URL is needed for relative URLs - not used in return
  const path = urlObject.pathname // gets params out of base
  const query = Object.fromEntries(urlObject.searchParams.entries())
  return { path, query }
}

// empty pinia stor on logout
export function emptySecurityStore() {
  const security = useSecurityStore()

  security.loggedIn = false
  security.groups = {}
  security.name = ""
  security.email = ""
  security.attributes = {}
  security.apps = []
}

const allowedBaseRoutes = ["/", "/login", "/register", "/error-page"]
// used to remove all routes except the base routes on logout event
// routes are only built on login event or refresh or url typing - direct nav
export function removeSubRoutes() {
  router.getRoutes().forEach((route) => {
    // if route.path !== item in allowed then remove it
    //checking with path to prevent name clash
    if (allowedBaseRoutes.indexOf(route.path) === -1) {
      router.removeRoute(route.name)
    }
  })
}

// get user info from server
// side effect of pushing login event if not authenticated
export async function getUserInfo() {
  const security = useSecurityStore()
  let res = await getApiWrapper(BFFAuthURL + "/session-status", "GET")
  if (res.error) {
    emptySecurityStore()
    // dont remove subroutes in case of server/network issue causing response
    // todo show error popup
  }
  if (res.authenticated === true) {
    // handle data - send to store - not local storage - pinia temp only
    security.setUserState(res)
  } else {
    emptySecurityStore()
    removeSubRoutes()
    login()
  }
}

export async function getAuthState() {
  let res = await getApiWrapper(BFFAuthURL + "/session-status", "GET")
  if (res.error) {
    return { authenticated: false, error: true }
  } else if (res.authenticated === true) {
    return { authenticated: true, userData: res }
  } else {
    return { authenticated: false }
  }
}

export async function login() {
  let res = await getApiWrapper(BFFAuthURL + "/login", "GET")
  if (res.error) {
    router.push({ name: "error-page" })
    console.log(res.msg.message)
  }
  window.location.href = res.url
  //the user is now redirected to the login page on keycloaks server
  // successful login will mean a redirect back to the app with params
  // this triggers the callback event
  // the router object picks up this event due to inclusion of route params
}

export async function register() {
  let res = await getApiWrapper(BFFAuthURL + "/register", "GET")
  if (res.error) {
    router.push({ name: "error-page" })
  }
  window.location.href = res.url
  //the user is now redirected to the login page on keycloaks server
  // successful login will mean a redirect back to the app with params
  // this triggers the callback event
  // the router object picks up this event due to inclusion of route params
}

export async function filterUserGroups(
  allowedRoles: string[],
  groups: Record<string, string>
) {
  // loop through object with for loop - groups
  // check if any of the groups values (arrays) contain any of the values listed in akkowedRoles
  for (let each in groups) {
    // loop through this array to see if any of the values match the allowedRoles
    if (allowedRoles.indexOf(groups[each]) !== -1) {
      return true
    }
  }
  return false
}

export async function convertGroupsToAppsArray(groups: Record<string, string>) {
  if (groups.app_roles !== undefined) {
    let appsArray = Object.keys(groups["app_roles"])
    if (appsArray.length === 0) {
      return []
    } else {
      return appsArray
    }
  } else return []
}
