<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12">
          <!-- Main content here -->
          <slot name="main-content"></slot>
        </div>
      </div>
    </div>
    <div class="intro-x col-span-12 2xl:col-span-3">
      <div class="-mb-10 pb-10 xl:border-l">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 2xl:gap-x-0 2xl:pl-6">
          <div class="col-span-12 mt-8">
            <!-- Sidebar content here -->
            <slot name="sidebar-content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
