import { defineStore } from "pinia"
import { convertGroupsToAppsArray } from "@/auth/authFunctions"

export const useSecurityStore = defineStore("security", {
  state: () => ({
    loggedIn: false,
    name: "",
    email: "",
    groups: {} as Record<string, string>,
    apps: {} as Record<string, string>,
    attributes: {},
    modalOpen: false,
  }),

  getters: {
    getFirstName(state) {
      let name = state.name.split(" ")
      return name[0]
    },
  },

  actions: {
    async setUserState(userData: {
      groups: Record<string, string>
      apps: Record<string, string>
      name: string
      email: string
    }) {
      this.loggedIn = true
      this.groups = userData.groups
      this.apps = await convertGroupsToAppsArray(userData.groups)
      this.name = userData.name
      this.email = userData.email
    },
  },
})
