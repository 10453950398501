import { createApp, defineAsyncComponent } from "vue"
import { createPinia } from "pinia"
import App from "./App.vue"
import router from "./router"
import "./assets/css/app.css"
import "@vuepic/vue-datepicker/dist/main.css"
import "@/assets/css/components/datepicker.css"

export const globalAppInstance = createApp(App)

// Dynamically import and register all Vue layout components in a directory
const layoutComponents: any = import.meta.glob("./layouts/**/*.vue", {
  eager: true,
})

for (const path in layoutComponents) {
  const pathSegments = path
    // Remove the "./" from the beginning
    .replace(/^\.\//, "")
    // Remove the ".vue" from the end
    .replace(/\.vue$/, "")
    // Split up kebabs
    .split("/")

  // Get the last element of the array (the component name)
  const componentName = pathSegments.length > 0 ? pathSegments.pop()! : ""

  // Split up kebabs
  const formattedComponentName = componentName
    .split("-")
    // Upper case
    .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    // Concatenation
    .join("")

  // Register layout components globally
  globalAppInstance.component(
    formattedComponentName,
    layoutComponents[path].default
  )
}

globalAppInstance.use(createPinia())
globalAppInstance.use(router)
globalAppInstance.component("VueDatePicker", defineAsyncComponent(() => import("@vuepic/vue-datepicker")));
globalAppInstance.mount("#app")
