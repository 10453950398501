<template>
  <div>
    <Dialog staticBackdrop :open="showModal" @close="setShowModal(false)">
      <Dialog.Panel>
        <div class="p-5 text-center">
          <Lucide icon="XCircle" class="mx-auto mt-3 h-16 w-16 text-warning" />
          <div class="mt-5 text-3xl">
            <slot :name="'header'">{{ header }}</slot>
          </div>
          <div class="mt-2 text-slate-400">
            <slot>{{ content }}</slot>
          </div>
        </div>
        <div class=" flex items-center justify-center px-5 pb-8 text-center">
          <Button
            type="button"
            variant="secondary"
            @click="cancel"
            class="mr-3 w-24 text-telenor-text"
          >
            Cancel
          </Button>

          <Button
            type="button"
            variant="success"
            class="w-24 border-0 bg-telenor text-telenor-super-light ml-3"
            @click="confirm"
          >
            {{ props.buttonConfirmText }}
          </Button>
        </div>
      </Dialog.Panel>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import Lucide from "@/components/Base/Lucide"
import Button from "@/components/Base/Button"
import { Dialog } from "@/components/Base/Headless"

const emit = defineEmits(["update:openMe", "confirm", "cancel"])

const setShowModal = (value: boolean) => {
  showModal.value = value
}

const props = defineProps({
  openMe: {
    type: Boolean,
    required: true,
    default: false,
  },
  header: {
    type: String,
    required: false,
    default: "Success!",
  },
  content: {
    type: String,
    required: false,
    default: "Everything was successfully done",
  },
  buttonConfirmText: {
    type: String,
    required: false,
    default: "Ok",
  },
})

const confirm = () => {
  emit("confirm")
}

const cancel = () => {
  emit("cancel")
}

const showModal = computed({
  get: () => props.openMe,
  set: (value) => {
    emit("update:openMe", value)
  },
})
</script>
