<template>
  <div
    class="col-span-12 p-5 cursor-pointer sm:col-span-4 2xl:col-span-3 box zoom-in">
    <div class="text-base font-medium">
      <slot name="title"></slot>
    </div>
    <div class="text-slate-400 dark:text-darkmode-100">
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>
