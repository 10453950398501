<script setup lang="ts">
import "@/assets/css/vendors/simplebar.css"
import "@/assets/css/components/mobile-menu.css"
import { useRoute, useRouter } from "vue-router"
import { twMerge } from "tailwind-merge"
import logoUrl from "@/assets/images/tm_logo_white.svg"
import logoUrlBlack from "@/assets/images/tm_logo_black.svg"
import Lucide from "@/components/Base/Lucide"
import { useMenuStore } from "@/stores/menu"
import { useThemeStore } from "@/stores/theme"
import { type FormattedMenu, nestedMenu, linkTo } from "./mobile-menu"
import { watch, reactive, computed, onMounted, ref } from "vue"
import SimpleBar from "simplebar"
import UserDropdown from "../Custom/Theme/UserDropdown.vue"
import { useDarkModeStore } from "@/stores/dark-mode"

const darkModeStore = useDarkModeStore()
const route = useRoute()
const router = useRouter()
let formattedMenu = reactive<Array<FormattedMenu | "divider">>([])
const setFormattedMenu = (
  computedFormattedMenu: Array<FormattedMenu | "divider">
) => {
  Object.assign(formattedMenu, computedFormattedMenu)
}
const themeStore = useThemeStore()
const menuStore = useMenuStore()
const menu = computed(() =>
  nestedMenu(menuStore.menu(themeStore.theme.layout), route)
)

const activeMobileMenu = ref(false)
const setActiveMobileMenu = (active: boolean) => {
  activeMobileMenu.value = active
}

const scrollableRef = ref<HTMLDivElement>()

watch(menu, () => {
  setFormattedMenu(menu.value)
})

onMounted(() => {
  if (scrollableRef.value) {
    new SimpleBar(scrollableRef.value)
  }

  setFormattedMenu(menu.value)
})

const beforeEnter = (el: HTMLElement) => {
  el.style.height = "0"
}

const enter = (el: HTMLElement) => {
  el.style.height = el.scrollHeight + "px"
  el.addEventListener(
    "transitionend",
    () => {
      el.style.height = "auto"
    },
    { once: true }
  )
}

const leave = (el: HTMLElement) => {
  el.style.height =
    Number(el.scrollHeight) < 384 ? el.scrollHeight + "px" : "384px"
  setTimeout(() => {
    el.style.height = "0"
  })
}
</script>

<template>
  <!-- BEGIN: Mobile Menu -->

  <div
    class="group fixed inset-x-0 top-0 z-[60] w-screen border-b border-white/[0.08] bg-gray-200 backdrop-blur dark:bg-darkmode-800/70 md:hidden"
  >
    <div class="flex h-[70px] items-center px-3 sm:px-8">
      <a
        href="#"
        @click="(e) => e.preventDefault()"
        class="ml-1 block md:hidden"
      >
        <Lucide
          icon="Menu"
          class="mr-4 h-8 w-8 transform text-black dark:text-white"
          @click="
            () => {
              setActiveMobileMenu(!activeMobileMenu)
            }
          "
        />
      </a>
      <a href="/" class="mr-auto flex">
        <img
          alt="Midone Tailwind HTML Admin Template"
          class="w-44"
          :src="darkModeStore.darkModeValue ? logoUrl : logoUrlBlack"
        />
      </a>

      <UserDropdown />
    </div>
  </div>
  <transition
    enter-active-class="duration-500 ease-in-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-500 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="activeMobileMenu"
      @click="setActiveMobileMenu(false)"
      class="fixed top-0 z-[100] h-screen w-screen bg-black/30 md:hidden"
    ></div>
  </transition>
  <transition
    enter-active-class="duration-500 ease-in-out"
    enter-from-class="opacity-0 -translate-x-80"
    enter-to-class="opacity-100 translate-x-0"
    leave-active-class="duration-500 ease-in-out"
    leave-from-class="opacity-100 translate-x-0"
    leave-to-class="opacity-0 -translate-x-80"
  >
    <div
      v-if="activeMobileMenu"
      class="fixed top-0 z-[110] h-screen w-80 overflow-auto bg-white px-4 dark:bg-darkmode-600 md:hidden"
    >
      <div class="mt-4 flex justify-end">
        <Lucide
          class="h-8 w-8 text-black dark:text-white"
          icon="X"
          @click="setActiveMobileMenu(false)"
        />
      </div>
      <ul class="py-2">
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <div
            v-if="menu == 'divider'"
            class="my-6 border-b border-black dark:border-darkmode-400"
            :key="'divider-' + menuKey"
          ></div>
          <li v-else>
            <a
              :href="
                  menu.subMenu 
                    ? '#' 
                    : ((pageName: string | undefined) => {
                        try {
                          return router.resolve({
                            name: pageName,
                          }).fullPath;
                        } catch (err) {
                          return '';
                        }
                      })(menu.pageName)
                "
              @click="
                (event) => {
                  event.preventDefault()
                  linkTo(menu, router, setActiveMobileMenu)
                  setFormattedMenu([...formattedMenu])
                }
              "
            >
              <div
                class="my-1 flex h-14 w-full items-center rounded-lg pl-5 text-black hover:bg-gray-200 dark:text-white dark:hover:bg-darkmode-700"
              >
                <div>
                  <Lucide class="h-5" :icon="menu.icon" />
                </div>
                <div class="flex w-full justify-between">
                  <p class="pl-2 text-base">
                    {{ menu.title }}
                  </p>
                  <div v-if="menu.subMenu" class="pr-3">
                    <Lucide
                      :class="[
                        menu.activeDropdown ? 'rotate-180 transform' : '',
                      ]"
                      class="h-4"
                      icon="ChevronDown"
                    />
                  </div>
                </div>
              </div>
            </a>
            <Transition
              @enter="enter"
              @leave="leave"
              @before-enter="beforeEnter"
              enter-active-class="duration-500 ease-in-out"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="duration-500 ease-in-out"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ul
                v-if="menu.subMenu && menu.activeDropdown"
                class="overflow-hidden rounded-lg bg-gray-200 dark:bg-darkmode-700"
              >
                <li
                  v-for="(subMenu, subMenuKey) in menu.subMenu"
                  :key="subMenuKey"
                >
                  <a
                    :href="
                        subMenu.subMenu 
                          ? '#' 
                          : ((pageName: string | undefined) => {
                              try {
                                return router.resolve({
                                  name: pageName,
                                }).fullPath;
                              } catch (err) {
                                return '';
                              }
                            })(subMenu.pageName)
                      "
                    @click="
                      (event) => {
                        event.preventDefault()
                        linkTo(subMenu, router, setActiveMobileMenu)
                        setFormattedMenu([...formattedMenu])
                      }
                    "
                  >
                    <div class="flex h-14 w-full items-center rounded-lg pl-5">
                      <div>
                        <Lucide :icon="subMenu.icon" />
                      </div>
                      <div class="flex w-full justify-between">
                        <p class="pl-2 text-base">
                          {{ subMenu.title }}
                        </p>
                        <div v-if="subMenu.subMenu" class="pr-3">
                          <Lucide
                            :class="subMenu.activeDropdown ? ' rotate-180' : ''"
                            class="h-4"
                            icon="ChevronDown"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                  <Transition
                    @enter="enter"
                    @leave="leave"
                    @before-enter="beforeEnter"
                    enter-active-class="duration-500 ease-in-out"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="duration-500 ease-in-out"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                    v-if="subMenu.subMenu"
                  >
                    <ul
                      v-if="subMenu.subMenu && subMenu.activeDropdown"
                      class="mx-2 overflow-hidden rounded-lg bg-gray-300 dark:bg-darkmode-600"
                    >
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                        :key="lastSubMenuKey"
                      >
                        <a
                          :href="
                              lastSubMenu.subMenu 
                                ? '#' 
                                : ((pageName: string | undefined) => {
                                    try {
                                      return router.resolve({
                                        name: pageName,
                                      }).fullPath;
                                    } catch (err) {
                                      return '';
                                    }
                                  })(lastSubMenu.pageName)
                            "
                          @click="
                            (event) => {
                              event.preventDefault()
                              linkTo(lastSubMenu, router, setActiveMobileMenu)
                              setFormattedMenu([...formattedMenu])
                            }
                          "
                        >
                          <div
                            class="flex h-14 w-full items-center rounded-lg px-3"
                          >
                            <div class="h-5 pr-2">
                              <Lucide :icon="lastSubMenu.icon" />
                            </div>
                            <div class="text-base">
                              <p>
                                {{ lastSubMenu.title }}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </Transition>
                </li>
              </ul>
            </Transition>
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </div>
  </transition>

  <!-- END: Mobile Menu -->
</template>
