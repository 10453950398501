<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-4">
      <div class="pb-10 -mb-10">
        <div class="grid grid-cols-12 2xl:pl-6 gap-x-6 2xl:gap-x-0 gap-y-6">
          <div class="col-span-12 mt-8">
            <!-- Left content here -->
            <slot name="left-content"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 2xl:col-span-4">
      <div class="pb-10 -mb-10 2xl:border-l">
        <div class="grid grid-cols-12 2xl:pl-6 gap-x-6 2xl:gap-x-0 gap-y-6">
          <div class="col-span-12 mt-8">
            <!-- Center content here -->
            <slot name="center-content"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 2xl:col-span-4">
      <div class="pb-10 -mb-10 2xl:border-l">
        <div class="grid grid-cols-12 2xl:pl-6 gap-x-6 2xl:gap-x-0 gap-y-6">
          <div class="col-span-12 mt-8">
            <!-- Right content here -->
            <slot name="right-content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
