import {
  genericRouteGuardResult,
  parseUrlStringForAppId,
} from "@/router/routerFuncs"

const routes = [
  {
    path: "/components",
    name: "components",
    meta: { title: "Component Library" },
    component: () => import("@apps/components/src/App.vue"),
    children: [
      {
        path: "",
        name: "components.home",
        component: () => import("@apps/components/src/pages/Home.vue"),
      },
      {
        path: "inputs",
        name: "components.inputs",
        meta: { title: "Inputs" },
        component: () => import("@apps/components/src/pages/Inputs.vue"),
      },
      {
        path: "dropdowns",
        name: "components.dropdowns",
        meta: { title: "Dropdowns" },
        component: () => import("@apps/components/src/pages/DropDowns.vue"),
      },
      {
        path: "buttons",
        name: "components.buttons",
        meta: { title: "Buttons" },
        component: () => import("@apps/components/src/pages/Buttons.vue"),
      },
      {
        path: "layouts",
        name: "components.layouts",
        meta: { title: "Layouts" },
        component: () => import("@apps/components/src/pages/Layouts.vue"),
      },
      {
        path: "tables",
        name: "components.tables",
        meta: { title: "Tables" },
        component: () => import("@apps/components/src/pages/Tables.vue"),
      },
    ],
  },
]

const menuIndex = 1

const menuItems = [
  {
    icon: "Calendar",
    pageName: "components",
    title: "Component Library",
    subMenu: [
      {
        icon: "Calendar",
        pageName: "components.home",
        title: "Home",
        // subMenu: [
        //   {
        //     icon: "Calendar",
        //     pageName: "components.home",
        //     title: "Home",
        //   },
        // ],
      },
      {
        icon: "TextCursorInput",
        pageName: "components.inputs",
        title: "Inputs",
      },
      {
        icon: "ListChecks",
        pageName: "components.dropdowns",
        title: "Dropdowns",
      },
      {
        icon: "MousePointer2",
        pageName: "components.buttons",
        title: "Buttons",
      },
      {
        icon: "LayoutTemplate",
        pageName: "components.layouts",
        title: "Layouts",
      },
      {
        icon: "Table",
        pageName: "components.tables",
        title: "Tables",
      },
    ],
  },
]

export const submoduleRouteGuard = (router: any) => {
  return async (to: any, from: any, next: any) => {
    // step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
    let guardRequirements = {
      appId: "components",
      baseRoles: ["components-admin"],
    }
    let parsedAppID: string | null = parseUrlStringForAppId(to.path)
    if (parsedAppID === "components") {
      // step 2 - inside of this submodule - check if the role is allowed/valid
      const routeGuardAllowed = await genericRouteGuardResult(guardRequirements)
      if (routeGuardAllowed) {
        next()
      } else {
        // abort navigation
        next("/") // Redirect to home page - show no access message
      }
    } else {
      next() // Allow other submodule routes and routes
    }
    if (to.path !== from.path) {
      window.scrollTo(0, 0) // Scroll to top when the route changes (ignoring hash/params)
    }
  }
}

export default {
  routes,
  menuIndex,
  menuItems,
}
