<template>
  <div class="relative" v-on-click-outside="closeDrop">
    <div class="flex items-center">
      <button
        @click="open = !open"
        class="ml-2 flex items-center rounded-full px-3 py-2 text-black transition-all duration-300 ease-in hover:scale-105 hover:bg-gray-200 dark:text-white dark:hover:bg-darkmode-300 md:text-telenor-dark dark:md:text-white"
      >
        <p
          @click="!securityStore.loggedIn ? login() : ''"
          class="leading-tighter font-headingfont-bold body text-grey-200 e ml-1 mr-4 flex select-none items-center tracking-tighter duration-0 hover:cursor-pointer"
        >
          {{ securityStore.loggedIn ? securityStore.getFirstName : "Log in" }}
        </p>
        <Lucide icon="User" />
      </button>
    </div>
    <transition
      enter-active-class="duration-300 ease-in-out"
      enter-from-class="opacity-0 translate-y-6"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="duration-300 ease-in-out"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-6"
    >
      <div
        v-if="open"
        class="absolute right-0 top-12 mt-px w-56 rounded-md border border-gray-200 bg-white p-2 text-black shadow-xl dark:bg-darkmode-600 dark:text-white"
      >
        <div class="p-1 pb-2 font-normal" v-if="securityStore.loggedIn">
          <div class="font-medium">{{ securityStore.name }}</div>
          <div class="mt-0.5 text-xs text-black/70 dark:text-white/70">
            {{ securityStore.email }}
          </div>
        </div>
        <hr class="mb-2 bg-white/[0.08]" v-if="securityStore.loggedIn" />

        <button
          class="mb-2 flex w-full items-center rounded-md px-2 py-2 text-base hover:bg-gray-100 dark:hover:bg-darkmode-400"
          @click="darkModeStore.toggleDarkMode()"
        >
          <Lucide icon="SunMoon" class="mr-2 h-4 w-4" />
          {{ darkModeStore.darkModeValue ? "Light Mode" : "Dark Mode" }}
        </button>
        <hr class="bg-white/[0.08]" v-if="securityStore.loggedIn" />
        <button
          class="my-2 mb-0 flex w-full items-center rounded-md px-2 py-2 text-base hover:bg-gray-100 dark:hover:bg-darkmode-400"
          @click="securityStore.loggedIn ? logout() : login()"
        >
          <Lucide icon="ToggleRight" class="mr-2 h-4 w-4" />
          {{ securityStore.loggedIn ? "Logout" : "Login" }}
        </button>
      </div></transition
    >
  </div>
</template>

<script setup lang="ts">
import { useThemeStore } from "@/stores/theme"
import { useSecurityStore } from "@/stores/security"
import { useDarkModeStore } from "@/stores/dark-mode"
import { Menu } from "@/components/Base/Headless"
import Lucide from "@/components/Base/Lucide"
import { computed, ref } from "vue"
import { logout, login } from "@/auth/authFunctions"
import { vOnClickOutside } from "@vueuse/components"

const themeStore = useThemeStore()
const securityStore = useSecurityStore()
const darkModeStore = useDarkModeStore()
const theme = computed(() => themeStore.themeValue.name)

const open = ref(false)

const closeDrop = () => {
  open.value = false
}
</script>
