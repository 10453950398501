// stores/userInfo.ts
import { defineStore } from "pinia"
import { useLocalStorage, type RemovableRef } from "@vueuse/core"

export const useModalStore = defineStore("modal-store", {
  // state
  state: () => ({
    modal: {
      success: { header: "", msg: "", show: false },
      failed: { header: "", msg: "", show: false },
      error: { header: "", msg: "", show: false },
      warning: { header: "", msg: "", show: false },
      notAllowed: { header: "", msg: "", show: false },
    },
    backlog: [] as activateModalInput[],
    activeModal: false,
    toast: {
      success: {
        msg: "",
        show: 0,
        duration: 3000,
        onClick: undefined as Function | undefined | string,
        date: undefined as Date | undefined,
      },
      failed: {
        msg: "",
        show: 0,
        duration: 3000,
        onClick: undefined as Function | undefined | string,
        date: undefined as Date | undefined,
      },
      message: {
        msg: "",
        show: 0,
        duration: 3000,
        onClick: undefined as Function | undefined | string,
        date: undefined as Date | undefined,
      },
      delete: {
        msg: "",
        show: 0,
        duration: 3000,
        onClick: undefined as Function | undefined | string,
        date: undefined as Date | undefined,
      },
      warning: {
        msg: "",
        show: 0,
        duration: 3000,
        onClick: undefined as Function | undefined | string,
        date: undefined as Date | undefined,
      },
    },
    activeToasts: useLocalStorage("active-toasts", [] as activateToastInput[]),
  }),
  // actions
  actions: {
    async activateModal({ header, msg, modalType }: activateModalInput) {
      if (!this.activeModal) {
        // set values
        this.modal[modalType].header = header
        this.modal[modalType].msg = msg
        this.modal[modalType].show = true
        // say that theres a current active modal
        this.activeModal = true
      } else {
        // if theres alredy an active modal add to backlog
        this.backlog.push({ header, msg, modalType })
      }
    },
    async deactivateModal(modalType: ModalTypes) {
      // deactivate modal
      this.modal[modalType].show = false
      // no longen an active modal
      this.activeModal = false

      //check backlog
      if (this.backlog.length >= 1) {
        // set timeout because of modal animation
        setTimeout(async () => {
          await this.activateModal(this.backlog[0])
          this.backlog.shift()
        }, 500)
      } else {
        // if theres nothing in the backlog clear values
        setTimeout(async () => {
          this.modal[modalType].header = ""
          this.modal[modalType].msg = ""
        }, 300)
      }
    },
    // toast
    async activateToast({
      msg,
      toastType,
      duration = 3000,
      onClick = undefined,
      addToArray = true,
      date = new Date(),
    }: activateToastInput) {
      // set values
      this.toast[toastType].msg = msg
      this.toast[toastType].duration = duration
      this.toast[toastType].onClick = onClick
      this.toast[toastType].date = date

      // add values to the active toasts array
      if (addToArray) {
        this.activeToasts.push({
          msg,
          toastType,
          duration,
          onClick: onClick?.toString(),
          date: date,
        })
      }
      // trigger the toast
      this.toast[toastType].show++
    },
    async activateBacklogToasts() {
      // go through the active toasts array
      this.activeToasts.forEach(async (toast) => {
        // set timeout is because unfortunate timings if not
        setTimeout(async () => {
          await this.activateToast({
            msg: toast.msg,
            toastType: toast.toastType,
            duration: toast.duration,
            addToArray: false, // run with this to not double the toasts
            onClick: new Function("return " + toast?.onClick)(),
            date: new Date(toast.date),
          })
        }, 1)
      })
    },
  },
})

type activateModalInput = {
  header: string
  msg: string
  modalType: ModalTypes
}

type ModalTypes = "success" | "error" | "warning" | "notAllowed"

type activateToastInput = {
  msg: string
  toastType: ToastTypes
  duration?: number
  addToArray?: boolean
  onClick?: Function | undefined | string
  date?: Date
}

type ToastTypes = "success" | "failed" | "message" | "delete" | "warning"
