const gitUrl =
  "https://submodules-read-token:glpat-RqC_b5cDvV_vyaP5uPFY@tmaeurgit1.i.telenormaritime.com/star-fleet/submodules"

const submodules = [
  {
    module: "wifipkgmgmnt",
    path: `${gitUrl}/wifi/wifiPkgMgmnt.git`,
    tag: "v0.0.13",
  },
  {
    module: "uhsman",
    path: `${gitUrl}/uhs/uhsman-sub.git`,
    tag: "v1.0.6",
  },
  {
    module: "mwb1",
    path: `${gitUrl}/data-analysis/mwb1.git`,
    tag: "v0.0.1",
  },
  {
    module: "sim",
    path: `${gitUrl}/sim-esim/sim-v2.git`,
    tag: "v0.0.15",
  },
  {
    module: "datagui",
    path: `${gitUrl}/uhs/data-gui.git`,
    tag: "v1.0.1",
  },
  {
    module: "client-management",
    path: `${gitUrl}/uhs/client-management.git`,
    tag: "3809bb1ae5cdee732bd9f7770c44542fb8fa1b99",
  },
  {
    module: "components",
    path: `${gitUrl}/components`,
  },
  {
    module: "private5g",
    path: `${gitUrl}/5g/private-5g.git`,
    tag: "v1.0.4",
  },
]

export default submodules
