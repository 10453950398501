<script setup lang="ts">
import { computed } from "vue"
import Lucide from "@/components/Base/Lucide"
import Breadcrumb from "@/components/Base/Breadcrumb"
import { RouterLink, useRoute, useRouter } from "vue-router"
import { useThemeStore } from "@/stores/theme"
import UserDropdown from "@/components/Custom/Theme/UserDropdown.vue"
import MobileMenu from "@/components/MobileMenu/MobileMenu.vue"
import { useMenuStore } from "@/stores/menu"

const themeStore = useThemeStore()
const theme = computed(() => themeStore.themeValue.name)
const menuStore = useMenuStore()

const route = useRoute()

//Breadcrums
const breadcrumbSegments = computed(() => {
  const path = route.path
  const segments = path
    .split("/")
    .filter(Boolean)
    .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))

  if (segments[0] === "Wifipkgmgmnt") {
    menuStore.menuValue.filter((item) => {
      if (item.pageName === "wifipkgmgmnt_table") {
        segments[0] = item.title
      }
    })
  }
  return segments
})
</script>

<template>
  <!-- Enigma -->
  <MobileMenu />

  <div
    class="relative z-[51] flex h-10 w-full items-center justify-end border-b border-slate-200 bg-gradient-to-r from-gray-100 from-0% via-gray-100/70 via-50% to-gray-100 to-100% px-4 backdrop-blur-lg dark:bg-gradient-to-r dark:from-darkmode-700 dark:via-darkmode-700/70 dark:to-darkmode-700 md:sticky md:top-0 md:h-[67px]"
  >
    <div :class="theme === 'tinker' ? 'flex w-full' : ''">
      <!-- BEGIN: Logo -->

      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <Breadcrumb light class="-intro-x mr-auto flex text-sm md:text-sm">
        <Breadcrumb.Link class="text-primary dark:text-light" to="/">
          Home
        </Breadcrumb.Link>
        <div
          v-if="breadcrumbSegments.length > 2"
          class="flex items-center justify-center text-primary dark:text-light lg:hidden"
        >
          <Lucide icon="ChevronRight" class="h-4 w-4" />...
        </div>
        <div
          v-for="(element, index) in breadcrumbSegments"
          class="flex items-center justify-center text-sm text-primary dark:text-light"
          :class="breadcrumbSegments.length - 2 > index ? 'hidden lg:flex' : ''"
        >
          <Lucide icon="ChevronRight" class="h-4 w-4" />
          <!-- <Breadcrumb.Link
            :to="`/${breadcrumbSegments.slice(0, index + 1).join('/')}`"
          > -->
          {{ element.slice(0, 12) + (element.length > 12 ? "..." : "") }}
          <!-- </Breadcrumb.Link> -->
        </div>
      </Breadcrumb>
      <!-- END: Breadcrumb -->

      <UserDropdown class="hidden md:block" />
    </div>
  </div>
</template>
