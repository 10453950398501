import { parseUrlStringForAppId } from "@/router/routerFuncs";
import { genericRouteGuardResult } from "@/router/routerFuncs";

const routes = [
	{
		path: "/uhsman",
		name: "UHSMAN",
		component: () => import("@apps/uhsman/src/App-uhsman.vue"),
		children: [
			{
				path: "dashboard",
				name: "uhsman.dashboard",
				component: () => import("@apps/uhsman/src/pages/Dashboard.vue"),
			},
			{
				path: ":operator",
				name: "operator",
				component: () => import("@apps/uhsman/src/pages/OperatorDash.vue"),
			},
			{
				path: ":operator/:vesselId",
				name: "vessel",
				component: () => import("@apps/uhsman/src/pages/Vessel.vue"),
			},
			{
				path: ":operator/:vesselId/:alarmId",
				name: "alarm",
				component: () => import("@apps/uhsman/src/pages/Alarm.vue"),
			},
		],
	},
];

const menuItems = [
	{
		icon: "Bell",
		pageName: "uhsman.dashboard",
		title: "UHSMAN",
		// parent: "uhs",
	},
];

const menuIndex = 2;

export const submoduleRouteGuard = (router: any) => {
	return async (to: any, from: any, next: any) => {
		// step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
		let guardRequirements = {
			appId: "uhsman",
			baseRoles: ["uhsman-admin", "uhsman-colorline"],
		};
		let parsedAppID: string | null = parseUrlStringForAppId(to.path);
		if (parsedAppID === guardRequirements.appId) {
			// step 2 - inside of this submodule - check if the role is allowed/valid
			const routeGuardAllowed = await genericRouteGuardResult(
				guardRequirements
			);
			if (routeGuardAllowed) {
				next();
			} else {
				// abort navigation
				next("/"); // Redirect to home page - show no access message
			}
		} else {
			next(); // Allow other submodule routes and routes
		}
	};
};

export default {
	routes,
	menuIndex,
	menuItems,
};
