<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 mt-8">
      <div class="flex items-center h-10 intro-y justify-center text-2xl">
        <slot name="header"></slot>
      </div>
      <div class="mt-5 intro-y">
        <div
          :class="[
            'relative mt-5 intro-y',
            'before:box before:absolute before:inset-x-3 before:mt-3 before:h-full before:bg-slate-50 before:content-[\'\']',
          ]">
          <div class="grid grid-cols-12 box">
            <div class="flex flex-col justify-center col-span-12 px-8 py-12">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
