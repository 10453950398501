import { type Icon } from "@/components/Base/Lucide/Lucide.vue"
import { type Themes } from "@/stores/theme"
import { defineStore } from "pinia"

export interface Menu {
  icon: Icon
  title: string
  pageName?: string
  subMenu?: Menu[]
  ignore?: boolean
}

export interface MenuState {
  menuValue: Array<Menu | "divider">
}

export const useMenuStore = defineStore("menu", {
  state: (): MenuState => ({
    menuValue: [
      {
        icon: "Home",
        pageName: "homepage",
        title: "Homepage",
      },
    ],
  }),
  getters: {
    menu: (state) => (menuType: string) => {
      // Use menuType to determine what to return
      return state.menuValue
    },
  },
})
