<script setup lang="ts">
import { useThemeStore, type Themes } from "@/stores/theme"
import SideMenu from "@/components/Custom/Theme/SideMenu.vue"
import { useDarkModeStore } from "@/stores/dark-mode"

const themeStore = useThemeStore() // init themes
const darkModeStore = useDarkModeStore() // init mode

const darkVal = darkModeStore.setDarkModeState()

// theme store required here to init app
</script>

<template>
  <div>
    <!-- <ThemeSwitcher /> -->
    <SideMenu />
  </div>
</template>
